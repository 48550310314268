import { GloboId } from './GloboId'
import { 
  executeQueue, 
  GLOBOID_AVAILABLE_CALLBACK_QUEUE_NAME,
  GLOBOID_READY_CALLBACK_QUEUE_NAME
} from './StageQueueManager'

class GloboIdClient {
  constructor() {
    this.initGloboId()
  }

  async initGloboId() {
    this.globoId = new GloboId()
    this.stageQueueMap = {}
    await executeQueue(this.stageQueueMap, GLOBOID_AVAILABLE_CALLBACK_QUEUE_NAME, this.globoId)
  }

  async setup(settings) {
    this.settings = settings
    const setupResult = await this.globoId.setup(this.settings)
    await executeQueue(this.stageQueueMap, GLOBOID_READY_CALLBACK_QUEUE_NAME, this.globoId)
    return setupResult
  }
}

class GloboIdClientMap {
  constructor() {
    this.globoIdMap = {}
  }

  async initNewGloboIdClient(settings) {
    const clientId = settings.clientId
    this.globoIdMap[clientId] = new GloboIdClient()
    return await this.globoIdMap[clientId].setup(settings)
  }

  getGloboIdClient(clientId) {
    return this.globoIdMap[clientId]
  }
}

export default GloboIdClientMap
