const GLOBOID_TOKENS_STORAGE_KEY = 'globoid-tokens'
const KC_CALLBACK_STORAGE_KEY = 'kc-callback'

const SESSION_STORAGE = 'session'
const LOCAL_STORAGE = 'local'


function GetTokenStorageKey(clientId) {
  return GLOBOID_TOKENS_STORAGE_KEY + '-' + clientId
}

function GetKcStorageKey(stateId) {
  return KC_CALLBACK_STORAGE_KEY + '-' + stateId
}

function GetStorage(type) {
  return type == LOCAL_STORAGE ? window.localStorage : window.sessionStorage
}

export {GLOBOID_TOKENS_STORAGE_KEY,
  GetTokenStorageKey,
  GetKcStorageKey,
  SESSION_STORAGE,
  LOCAL_STORAGE,
  GetStorage
}
