class Log {
  setDebug(value) {
    this._debug = value
  }

  defaultColor() {
    return 'color:#038cfc'
  }

  getMessage(msg) {
    return `%c[globoId] - ${msg}`
  }

  info(msg) {
    console.info(this.getMessage(msg), this.defaultColor())
  }

  error(msg) {
    console.error(this.getMessage(msg), this.defaultColor())
  }

  debug(msg) {
    if (this._debug) {
      console.debug(this.getMessage(msg), this.defaultColor())
    }
  }
}

export default new Log()
