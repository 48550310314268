import SessionManager from './SessionManager'
import jwt_decode from 'jwt-decode'
import {isEmpty} from 'underscore'
import {GetStorage, GetTokenStorageKey} from './StorageKeys'

class TokenSessionManager extends SessionManager {

  constructor(settings) {
    console.debug('Setting up TokenSessionManager')
    if(!settings.tokenStorageType) {
      settings.tokenStorageType = 'session'
    }

    super(settings)
  }

  async setup() {
    const setupRes = await super.setup()
    const userSessionPayload = this.loadUserSession()

    this.settings = {
      ...userSessionPayload,
      ...this.settings,
    }

    return setupRes
  }

  async initialize() {
    console.debug('TokenSessionManager initializing')
    const initRes = await super.initialize()

    if (await this.isLogged()) {
      console.debug('TokenSessionManager saving user session')
      this.saveUserSession()
    } else {
      console.debug('TokenSessionManager Not logged')
    }

    return initRes
  }

  isLogged() {
    return (this.isKcLogged() || this.isTokenLogged()) && this.hasReguiredCookies()
  }

  hasReguiredCookies() {
    if(this.settings.considerGloboidCookie) {
      console.debug('TokenSessionManager hybrid auth detected, verifying cookies')
      return this.hasNecessaryCookies(['GLOBO_ID'])
    } else {
      return true
    }
  }

  isTokenLogged() {
    console.debug('Trying to auth using the stored tokens')

    const session = this.loadUserInfo()
    if (!session) {
      return false
    } else {
      return (new Date().getTime() / 1000) <= session.exp
    }
  }

  isKcLogged() {
    if (!this.keyCloakInstance) {
      console.debug('TokenSessionManager no keyCloakInstance running')
      return false
    }

    console.debug('TokenSessionManager keyCloakInstance found')

    return this.keyCloakInstance.authenticated
  }

  getStorageKey() {
    return GetTokenStorageKey(this.settings.clientId)
  }

  saveUserSession() {
    if (this.isKcLogged()) {
      const userSessionPayload = {
        access_token: this.keyCloakInstance.token,
        id_token: this.keyCloakInstance.idToken,
      }

      GetStorage(this.tokenStorageType).setItem(
        this.getStorageKey(), JSON.stringify(userSessionPayload)
      )
    }
  }

  loadUserSession() {
    const userSessionPayload = GetStorage(this.settings.tokenStorageType)
      .getItem(this.getStorageKey())

    if (userSessionPayload) {
      return JSON.parse(userSessionPayload)
    }
  }

  clearUserSession() {
    const storage = GetStorage(this.settings.tokenStorageType)
    storage.removeItem(this.getStorageKey())
  }

  async logout() {
    this.clearUserSession()
    super.logout()
  }

  loadUserInfo() {
    const userSession = this.loadUserSession()
    if (!isEmpty(userSession)) {
      const decoded = jwt_decode(userSession['id_token'])
      decoded.photoUrl = decoded.picture
      decoded.userName = decoded.name
      return decoded
    }
  }

  getTokens() {
    if(this.isLogged()) {
      const key = GetTokenStorageKey(this.settings.clientId)
      const tokens = GetStorage(this.settings.tokenStorageType).getItem(key)
      return JSON.parse(tokens)
    } else {
      throw new Error('[TokenSessionManager] user not logged')
    }
  }

}

export {TokenSessionManager}