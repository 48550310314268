import Log from './Log'

const GLOBOID_AVAILABLE_CALLBACK_QUEUE_NAME = 'domainSetupStageQueue'
const GLOBOID_READY_CALLBACK_QUEUE_NAME = 'applicationUsageStageQueue'

const executeQueue = async (context, key, globoId) => {
  Log.debug(`Initilizing the queue window.glb.${key}`)
  const queue = context[key] || []

  if (queue.length) {
    Log.debug(`There ${queue.length > 1 ? 'are': 'is'} ${queue.length} element(s) inside the queue`)
    queue.forEach(async (callback) => await callback(globoId))
  } else {
    Log.debug('Queue is empty. Monkey patching the default push method')
  }

  queue.push = (callback) => {
    callback(globoId)
  }

  context[key] = queue
}

export {
  executeQueue,
  GLOBOID_AVAILABLE_CALLBACK_QUEUE_NAME,
  GLOBOID_READY_CALLBACK_QUEUE_NAME
}